import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export default () => {
	return (
		<Box maxWidth="400px" margin="0 auto">
			<Typography variant="h3">Success!</Typography>
			<Typography>Your message has been successfully sent.</Typography>
		</Box>
	);
};
