import React from 'react';
import Box from '@material-ui/core/Box';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export default () => {
	return (
		<Box maxWidth="70%" margin="0 auto">
			<Carousel autoPlay infiniteLoop dynamicHeight stopOnHover={false}>
				<div>
					<img
						src="/media/weddings/wedding_023_51656298071_o.jpg"
						alt="Weddings"
					/>
				</div>
				<div>
					<img src="/media/portraits/Renée_4720.jpg" alt="Renée" />
				</div>
				<div>
					<img src="/media/portraits/Kayla_0873.jpg" alt="Kayla" />
				</div>
				<div>
					<img src="/media/portraits/Artyom_1043.jpg" alt="Artyom" />
				</div>
				<div>
					<img
						src="/media/weddings/wedding_040_51657160195_o.jpg"
						alt="Weddings"
					/>
				</div>
				<div>
					<img
						src="/media/weddings/wedding_075_51657159935_o.jpg"
						alt="Weddings"
					/>
				</div>
				<div>
					<img
						src="/media/weddings/wedding_022_51657160310_o.jpg"
						alt="Weddings"
					/>
				</div>
			</Carousel>
		</Box>
	);
};
