import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const encode = (data) =>
	Object.keys(data)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
		.join('&');

export default () => {
	const history = useHistory();

	const [isError, setIsError] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': event.target.getAttribute('name'),
				name: event.target.name.value,
				email: event.target.email.value,
				message: event.target.message.value,
			}),
		})
			.then(() => history.push('/contact/success'))
			.catch(() => setIsError(true));
	};

	return (
		<>
			<Box maxWidth="400px" margin="0 auto">
				<form name="contact" method="post" onSubmit={handleSubmit}>
					<input type="hidden" name="form-name" value="contact" />
					<Box marginY={2}>
						<TextField fullWidth type="text" name="name" label="Your Name" />
					</Box>
					<Box marginY={2}>
						<TextField fullWidth type="email" name="email" label="Your Email" />
					</Box>
					<Box marginY={2}>
						<TextField
							fullWidth
							multiline
							name="message"
							label="Enter message here"
						/>
					</Box>
					<Box marginY={2}>
						<Button variant="contained" color="primary" type="submit">
							Send Message
						</Button>
					</Box>
				</form>
			</Box>
			<Snackbar
				open={isError}
				autoHideDuration={10000}
				onClose={() => setIsError(false)}
			>
				<Alert onClose={() => setIsError(false)} severity="error">
					There was an error sending the message. Please try again.
				</Alert>
			</Snackbar>
		</>
	);
};
